import { TypeImport } from '../types/constants';
import { FieldMap, MarketMapping, FieldValue } from '../types/types';

const yntFieldsTemplate: Array<FieldMap> = [
  {
    field: 'serialNumber',
    description: 'Vin Code/Chassis Number',
    required: true,
  },
  {
    field: 'operationVariantCode',
    description: 'Operation Variant Code',
    required: false,
    help: true
  },
  {
    field: 'customerName',
    description: 'Customer Name (Company Name)',
  },
  {
    field: 'customerEmail',
    description: 'Customer Email',
  },
  {
    field: 'salesRepFirstName',
    description: 'Sales Rep. First Name',
    required: false,
  },
  {
    field: 'salesRepLastName',
    description: 'Sales Rep. Last Name',
    required: false,
  },
  {
    field: 'salesRepEmail',
    description: 'Sales Rep. Email',
    required: false,
  },
  {
    field: 'salesRepContactNo',
    description: 'Sales Rep. Contact Num.',
  },
  {
    field: 'applicationVariantCode',
    description: 'Application Variant Code',
    help: true,
  },
];

const yntMRFieldsTemplate: Array<FieldMap> = [
  {
    field: 'serialNumber',
    description: 'Vin Code/Chassis Number',
    required: true,
  },
  {
    field: 'operationVariantCode',
    description: 'Operation Variant Code',
    required: false,
    help: true
  },
  {
    field: 'salesRepFirstName',
    description: 'Sales Rep. First Name',
    required: false,
  },
  {
    field: 'salesRepLastName',
    description: 'Sales Rep. Last Name',
    required: false,
  },
  {
    field: 'salesRepEmail',
    description: 'Sales Rep. Email',
    required: false,
  },
  {
    field: 'salesRepContactNo',
    description: 'Sales Rep. Contact Num.',
  },
  {
    field: 'applicationVariantCode',
    description: 'Application Variant Code',
    help: true,
  },
];

const getOptionsLanguageId = (market: string) => marketMapping.find((x) => x.marketISOCode === market)?.languageSC.map(x => { return { key: x.languageId, value: x.description } }) ?? [];
const getMarketId = (market: string) => marketMapping.find((x) => x.marketISOCode === market)?.marketId ?? null;

const additionalFields = (market: string) => {
  return {
    yntImport: [
      {
        field: 'languageId',
        required: true,
        label: 'Language',
        options: getOptionsLanguageId(market),
        type: 'number',
      },
    ],
    yntMrImport: [
      {
        field: 'languageId',
        required: true,
        label: 'Language',
        options: getOptionsLanguageId(market),
        type: 'number',
      },
    ],
    socialImport: [],
  }
};

const marketMapping: MarketMapping[] = [
  {
    marketISOCode: 'AR',
    marketName: 'Argentina',
    marketId: 5268,
    languageSC: [{ languageId: 5658, description: 'Spanish' }]
  },
  {
    marketISOCode: 'AT',
    marketName: 'Austria',
    marketId: 5144,
    languageSC: [{ languageId: 5241, description: 'German' }]
  },
  {
    marketISOCode: 'BE',
    marketName: 'Belgium',
    marketId: 5136,
    languageSC: [{ languageId: 5228, description: 'Dutch' }, { languageId: 5417, description: 'French' }]
  },
  {
    marketISOCode: 'BA',
    marketName: 'Bosnia and Herzegovina',
    marketId: 5172,
    languageSC: [{ languageId: 5432, description: 'Bosnian' }]
  },
  {
    marketISOCode: 'BR',
    marketName: 'Brazil',
    marketId: 5267,
    languageSC: [{ languageId: 5567, description: 'Portuguese' }]
  },
  {
    marketISOCode: 'BG',
    marketName: 'Bulgaria',
    marketId: 5158,
    languageSC: [{ languageId: 5331, description: 'Bulgarian' }]
  },
  {
    marketISOCode: 'CL',
    marketName: 'Chile',
    marketId: 5269,
    languageSC: [{ languageId: 5747, description: 'Spanish' }]
  },
  {
    marketISOCode: 'CO',
    marketName: 'Colombia',
    marketId: 5325,
    languageSC: [{ languageId: 5734, description: 'Spanish' }]
  },
  {
    marketISOCode: 'HR',
    marketName: 'Croatia',
    marketId: 5167,
    languageSC: [{ languageId: 5433, description: 'Croatian' }]
  },
  {
    marketISOCode: 'CZ',
    marketName: 'Czech Rep.',
    marketId: 5153,
    languageSC: [{ languageId: 5513, description: 'Czech' }]
  },
  {
    marketISOCode: 'DK',
    marketName: 'Denmark',
    marketId: 5132,
    languageSC: [{ languageId: 5220, description: 'Danish' }]
  },
  {
    marketISOCode: 'EE',
    marketName: 'Estonia',
    marketId: 5160,
    languageSC: [{ languageId: 5325, description: 'Estonian' },{ languageId: 5478, description: 'Russian' }]
  },
  {
    marketISOCode: 'FI',
    marketName: 'Finland',
    marketId: 5138,
    languageSC: [{ languageId: 5231, description: 'Finnish' },{ languageId: 5326, description: 'Swedish' }]
  },
  {
    marketISOCode: 'FR',
    marketName: 'France',
    marketId: 5139,
    languageSC: [{ languageId: 5516, description: 'French' }]
  },
  {
    marketISOCode: 'DE',
    marketName: 'Germany',
    marketId: 5145,
    languageSC: [{ languageId: 5241, description: 'German' }]
  },
  {
    marketISOCode: 'GB',
    marketName: 'Great Britain',
    marketId: 5134,
    languageSC: [{ languageId: 5509, description: 'English' }]
  },
  {
    marketISOCode: 'HU',
    marketName: 'Hungary',
    marketId: 5154,
    languageSC: [{ languageId: 5340, description: 'Hungarian' }]
  },
  {
    marketISOCode: 'IE',
    marketName: 'Ireland',
    marketId: 5151,
    languageSC: [{ languageId: 5249, description: 'English' }]
  },
  {
    marketISOCode: 'IT',
    marketName: 'Italy',
    marketId: 5133,
    languageSC: [{ languageId: 5222, description: 'Italian' }]
  },
  {
    marketISOCode: 'LV',
    marketName: 'Latvia',
    marketId: 5161,
    languageSC: [{ languageId: 5415, description: 'Latvian' },{ languageId: 5488, description: 'Russian' }]
  },
  {
    marketISOCode: 'LT',
    marketName: 'Lithuania',
    marketId: 5162,
    languageSC: [{ languageId: 5330, description: 'Lithuanian' }]
  },
  {
    marketISOCode: 'MX',
    marketName: 'Mexico',
    marketId: 5155,
    languageSC: [{ languageId: 5576, description: 'Spanish' }]
  },
  {
    marketISOCode: 'NL',
    marketName: 'Netherlands',
    marketId: 5137,
    languageSC: [{ languageId: 5229, description: 'Dutch' }]
  },
  {
    marketISOCode: 'NZ',
    marketName: 'New Zealand',
    marketId: 5444,
    languageSC: [{ languageId: 5748, description: 'English' }]
  },
  {
    marketISOCode: 'NO',
    marketName: 'Norway',
    marketId: 5140,
    languageSC: [{ languageId: 5235, description: 'Norwegian' }]
  },
  {
    marketISOCode: 'PE',
    marketName: 'Peru',
    marketId: 5270,
    languageSC: [{ languageId: 5733, description: 'Spanish' }]
  },
  {
    marketISOCode: 'PL',
    marketName: 'Poland',
    marketId: 5146,
    languageSC: [{ languageId: 5450, description: 'Polish' }]
  },
  {
    marketISOCode: 'PT',
    marketName: 'Portugal',
    marketId: 5150,
    languageSC: [{ languageId: 5420, description: 'Portuguese' }]
  },
  {
    marketISOCode: 'RO',
    marketName: 'Romania',
    marketId: 5157,
    languageSC: [{ languageId: 5324, description: 'Romanian' }]
  },
  {
    marketISOCode: 'RU',
    marketName: 'Russia',
    marketId: 5170,
    languageSC: [{ languageId: 5553, description: 'Russian' }]
  },
  {
    marketISOCode: 'SA',
    marketName: 'Saudi Arabia',
    marketId: 5240,
    languageSC: [{ languageId: 5358, description: 'Arabic' }]
  },
  {
    marketISOCode: 'RS',
    marketName: 'Serbia',
    marketId: 5169,
    languageSC: [{ languageId: 5434, description: 'Serbian' }]
  },
  {
    marketISOCode: 'SK',
    marketName: 'Slovakia',
    marketId: 5218,
    languageSC: [{ languageId: 5517, description: 'Slovak' }]
  },
  {
    marketISOCode: 'SI',
    marketName: 'Slovenia',
    marketId: 5166,
    languageSC: [{ languageId: 5431, description: 'Slovenian' }]
  },
  {
    marketISOCode: 'ZA',
    marketName: 'South Africa',
    marketId: 5230,
    languageSC: [{ languageId: 5355, description: 'English' }]
  },
  {
    marketISOCode: 'ES',
    marketName: 'Spain',
    marketId: 5148,
    languageSC: [{ languageId: 5419, description: 'Spanish' }]
  },
  {
    marketISOCode: 'SE',
    marketName: 'Sweden',
    marketId: 5141,
    languageSC: [{ languageId: 5237, description: 'Swedish' }]
  },
  {
    marketISOCode: 'CH',
    marketName: 'Switzerland',
    marketId: 5142,
    languageSC: [{ languageId: 5528, description: 'French' },{ languageId: 5527, description: 'German' }]
  },
  {
    marketISOCode: 'TH',
    marketName: 'Thailand',
    marketId: 5245,
    languageSC: [{ languageId: 5370, description: 'English' },{ languageId: 5629, description: 'Thai' }]
  },
  {
    marketISOCode: 'TR',
    marketName: 'Turkey',
    marketId: 5173,
    languageSC: [{ languageId: 5560, description: 'Turkish' }]
  },
  {
    marketISOCode: 'AE',
    marketName: 'UAE',
    marketId: 5235,
    languageSC: [{ languageId: 5358, description: 'Arabic' }]
  },
  {
    marketISOCode: 'UA',
    marketName: 'Ukraine',
    marketId: 5174,
    languageSC: [{ languageId: 5540, description: 'Ukrainian' }]
  },
  {
    marketISOCode: 'SG',
    marketName: 'Singapore',
    marketId: 5248,
    languageSC: [{ languageId: 5606, description: 'English' }],
  },
];

const yntMarketMapping: MarketMapping[] = [
  {
    marketISOCode: 'AR',
    marketName: 'Argentina',
    marketId: 5268,
    languageSC: [{ languageId: 5658, description: 'Spanish' }]
  },
  {
    marketISOCode: 'AT',
    marketName: 'Austria',
    marketId: 5144,
    languageSC: [{ languageId: 5241, description: 'German' }]
  },
  {
    marketISOCode: 'BE',
    marketName: 'Belgium',
    marketId: 5136,
    languageSC: [{ languageId: 5228, description: 'Dutch' }, { languageId: 5417, description: 'French' }]
  },
  {
    marketISOCode: 'BR',
    marketName: 'Brazil',
    marketId: 5267,
    languageSC: [{ languageId: 5567, description: 'Portuguese' }]
  },
  {
    marketISOCode: 'CZ',
    marketName: 'Czech Rep.',
    marketId: 5153,
    languageSC: [{ languageId: 5513, description: 'Czech' }]
  },
  {
    marketISOCode: 'DE',
    marketName: 'Germany',
    marketId: 5145,
    languageSC: [{ languageId: 5241, description: 'German' }]
  },
  {
    marketISOCode: 'GB',
    marketName: 'Great Britain',
    marketId: 5134,
    languageSC: [{ languageId: 5509, description: 'English' }]
  },
  {
    marketISOCode: 'NL',
    marketName: 'Netherlands',
    marketId: 5137,
    languageSC: [{ languageId: 5229, description: 'Dutch' }]
  },
  {
    marketISOCode: 'PL',
    marketName: 'Poland',
    marketId: 5146,
    languageSC: [{ languageId: 5450, description: 'Polish' }]
  },
  {
    marketISOCode: 'SG',
    marketName: 'Singapore',
    marketId: 5248,
    languageSC: [{ languageId: 5606, description: 'English' }],
  },
  {
    marketISOCode: 'TH',
    marketName: 'Thailand',
    marketId: 5245,
    languageSC: [{ languageId: 5370, description: 'English' },{ languageId: 5629, description: 'Thai' }]
  },
];

const yntDefaultFields: Array<FieldValue> = [
  {
    field: 'serialNumber',
    value: 'VIN Code',
  },
  {
    field: 'operationVariantCode',
    value: 'Operation Variant Code',
  },
  {
    field: 'customerName',
    value: 'Customer Name',
  },
  {
    field: 'customerEmail',
    value: 'Customer Email',
  },
  {
    field: 'salesRepFirstName',
    value: 'Sales Rep First Name',
  },
  {
    field: 'salesRepLastName',
    value: 'Sales Rep Last Name',
  },
  {
    field: 'salesRepEmail',
    value: 'Sales Rep Email',
  },
  {
    field: 'salesRepContactNo',
    value: 'Sales Rep Phone',
  },
  {
    field: 'applicationVariantCode',
    value: 'Application Variant Code',
  },
];

const yntMRDefaultFields: Array<FieldValue> = [
  {
    field: 'serialNumber',
    value: 'VIN Code',
  },
  {
    field: 'operationVariantCode',
    value: 'Operation Variant Code',
  },
  {
    field: 'salesRepFirstName',
    value: 'Sales Rep First Name',
  },
  {
    field: 'salesRepLastName',
    value: 'Sales Rep Last Name',
  },
  {
    field: 'salesRepEmail',
    value: 'Sales Rep Email',
  },
  {
    field: 'salesRepContactNo',
    value: 'Sales Rep Phone',
  },
  {
    field: 'applicationVariantCode',
    value: 'Application Variant Code',
  },
];

const getOptionsMarkets = (typeImport: string) => {
  if (typeImport === TypeImport.YNT || typeImport === TypeImport.YNT_MR) {
    return yntMarketMapping.map(x => { return { key: x.marketISOCode, value: x.marketName } });
  }
  return [];
};

const getAdditionalOptions = (typeImport: string, market: string) => {
  if (typeImport === TypeImport.YNT) return JSON.parse(JSON.stringify(additionalFields(market).yntImport));
  if (typeImport === TypeImport.YNT_MR) return JSON.parse(JSON.stringify(additionalFields(market).yntMrImport));
  return [];
};

export default {
  getYNTTemplate(): Array<FieldMap> {
    return JSON.parse(JSON.stringify(yntFieldsTemplate));
  },
  getYNTMrTemplate(): Array<FieldMap>{
    return JSON.parse(JSON.stringify(yntMRFieldsTemplate));
  },
  getYNTDefaultFields(): Array<FieldValue> {
    return JSON.parse(JSON.stringify(yntDefaultFields));
  },
  getYNTMRDefaultFields(): Array<FieldValue> {
    return JSON.parse(JSON.stringify(yntMRDefaultFields));
  },
  getAdditionalOptions,
  getOptionsMarkets,
  getMarketId,
};
